.profile-address {
  height: 80px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  justify-content: center;
  position: relative;
  &__name {
    color: #00d8f9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__address,
  &__name,
  &__description {
    word-break: break-word;
    width: 260px;
  }
  &__description {
    color: #dedede;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__remove {
    position: absolute;
    right: 20px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
