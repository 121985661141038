.delivery-slots {
  background: #fff;
  padding: 25px 0;
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 0px 6px #00000029;
  position: absolute;
  width: 300px;
  z-index: 2000;
  height: 410px;
  left: 0;
  &--radius6 {
    border-radius: 6px 6px 0 0;
  }
  &--overlay{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: transparent;
    position: fixed;
    cursor: pointer;
  }
  ul {
    list-style: none;
    margin: 0;
  }
  &__header {
    border-bottom: 1px solid #f2f2f2;
    padding: 0 20px;
    ul {
      font-size: 14px;
      font-weight: 500;

      border-radius: 12px;
      text-align: center;

      li {
        padding: 5px 10px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        border-radius: 6px;
        margin-bottom: 10px;
        color: #4b595c;
        text-shadow: 0px 0px 3px #00000029;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: #fff;
          background: transparent
            linear-gradient(180deg, #00b0f0 0%, #00d8f9 100%) 0% 0% no-repeat
            padding-box;
          text-shadow: none;
        }
        &.disabled {
          color: #fff;
          text-shadow: none;
          background: #dedede;
        }
      }
    }
  }
  &__list {
    text-align: center;
    padding-top: 5px;
    li {
      margin-bottom: 10px;
      text-shadow: 0px 0px 3px #00000029;
      color: #4b595c;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        color: #00d8f9;
        text-shadow: none;
      }
    }
  }
  &--bottom {
    bottom: 0;
    top: calc(100% + 10px);
    border-radius: 0 0 12px 12px;
    &:after {
      bottom: initial;
      top: -8px;
      border-top-width: 0;
      border-bottom: 8px solid #fff;
    }
  }
}
