.order {
  position: relative;
  @media screen and (max-width: 1024px) {
    margin-top: 71px;
    padding-top: 1px;
  }
  h1 {
    padding-top: 1px;
    @media screen and (max-width: 1024px) {
      font-size: 30px;
      margin: 20px 0 10px;
      padding: 0;
    }
  }
  &--status {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    margin-top: -86px;
    z-index: -1;
    &.active {
      height: 100vh;
      width: 100vw;
      animation: loadstatus 5s;
      background-image: none;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  @media screen and (max-width: 1024px) {
    .tabs {
      flex-wrap: wrap;
      &--item {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
  &--description {
    color: #adadad;
    margin-top: 10px;
    margin-bottom: 15px;
    @media screen and (max-width: 1024px) {
      margin-top: 5px;
      margin-bottom: 0 !important;
      font-size: 10px !important;
      font-weight: normal !important;
    }
  }
  &--search {
    @media screen and (max-width: 1024px) {
      &__text,
      &__container {
        display: none !important;
      }
    }
    &__text {
      margin-bottom: 10px;
    }
    &__container {
      display: flex;
      margin-bottom: 40px;
    }
    &__item {
      flex: 1;
      margin-right: 87px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@keyframes loadstatus {
  0% {
    z-index: -1;
    opacity: 0;
  }
  20% {
    background-color: #fff;
    z-index: 3;
    background-image: url("../../assets/img/animation.gif");
    background-size: 50px 50px;
    -webkit-background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
  }
  80% {
    background-color: #fff;
    z-index: 3;
    background-image: url("../../assets/img/animation.gif");
    background-size: 50px 50px;
    -webkit-background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
  }
  100% {
    background-image: none;
    background-color: none;
    z-index: -1;
    opacity: 0;
  }
}
