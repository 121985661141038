@import "../../../../sass/typography.scss";
.order--table {
  border: 0;
  border-collapse: collapse;
  text-align: left;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  td {
    padding: 10px;
  }
  thead {
    tr {
      td {
        @extend .bold-10;
      }
    }
  }
  tbody {
    tr {
      &.spacer {
        height: 20px;
      }
      &.active {
        td {
          background-color: #a8e7f6;
        }
      }
      td {
        @extend .bold-12;
        position: relative;
        background: #fff;
        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
  &__description {
    color: #b5b5b5;
  }
  &__cancel {
    text-align: center;
    cursor: pointer;

    &--active {
      width: 30px;
      height: 30px;
    }
    &--icon {
      width: 45px;
      height: 45px;
    }
  }
  &__attention {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.order--list{
  display: none;
  margin: 10px 0 28px;
  &--item{
    border-radius: 22px;
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
    &.active{
      background-color: #a8e7f6;
    }
    hr{
      width: 100%;
      height: 2px;
      background: white;
      border: none;
      border-radius: 20px;
      margin: 10px 0;
    }
    &__titles{
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-weight: bold;
      text-shadow: 0px 0px 3px #00000029;
      margin-bottom: 5px;
    }
    &__info{
      display: flex;
      flex-direction: column;
      font-size: 10px;
      font-weight: normal;
      color: #ADADAD;
    }
  }
  &__cancel{
    font-size: 10px;
    font-weight: bold;
    &--active{
      color: #00D8F9;
    }
    &--accept{
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 1;
      bottom: 20px;
      right: 60px;
    }
  }
  &__address{
    display: flex;
    flex-direction: column;
    &--shop, &--delivery, &--time{
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: bold;
      color: #4B595C;
      margin-bottom: 10px;
      span{
        font-size: 10px;
        font-weight: 500;
        color: #ADADAD;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    display: block;
  }
}
