$BLACK: #4b595c;
$GRAY: #dedede;
$SKYBLUE: #00d8f9;
$BLUE: #00b0f0;

$color-bg-outside: #fdfdfd;
$color-base: #00d8f9;
$color-grey: #e0dede;
$color-grey-active: #cfcdcd;
$color-mobile-bg-outside: #f8f8f8;
$color-red: #ff2727;
$color-maps-back: #eaffea;

/* FONT */
$color-font-info: #6d6c6c;

/* BUTTONS */
$color-button-grey: #636363;
$color-radio-not-active: #e2e2e2;

/* LINE */
$color-divider: #e8e8e8;
$color-scroll: #d8d8d8;

$color-placeholder: #757575;
