@import "../../sass/typography";

.button-select {
  color: #adadad;
  position: relative;
  &--active &__container {
    color: #424242;
  }
  &--active &__placeholder {
    top: 10px;
    font-size: 12px;
  }

  &__placeholder {
    user-select: none;
    position: absolute;
    top: 20px;
    left: 20px;
    @extend .medium-16;
  }
  &__container {
    border-radius: 12px;
    height: 60px;
    line-height: 70px;
    padding: 0 20px;
    box-shadow: 0px 0px 6px #00000029;
    background-color: #ffffff;
    cursor: pointer;
    @extend .medium-20;
    @media screen and (max-width: 1024px) {
      box-shadow: none;
      span{
          font-size: 16px;
      }
    }
    
    &--error {
      box-shadow: 0px 0px 12px #d60505c3;
    }
  }

  &__description {
    height: 25px;
    line-height: 25px;
    padding-left: 20px;
    @extend .medium-12;
    &--error {
      color: #d60505;
    }
  }
}
