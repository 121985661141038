@import "../../sass/typography.scss";

.app-button-center {
  cursor: pointer;
  width: 100%;
  height: 60px;
  text-align: center;
  border: 0;
  transition: 0.2s ease;
  background: transparent linear-gradient(180deg, #00b0f0 0%, #00d8f9 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  outline: none;
  color: #fff;
  &:active {
    transform: scale(0.8);
  }
  &:disabled {
    background: #dedede 0% 0% no-repeat padding-box;
  }
  &:focus {
    outline: none;
  }
  @extend .bold-25;
}
