@import "../../sass/typography.scss";

.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    margin-top: 71px;
  }

  &__content {
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 437px;
    text-align: center;
    @media screen and (max-width: 1024px) {
      min-height: 100%;
      width: 100%;
    }
    &--logo {
      align-items: center;
      justify-content: center;
      display: flex;
      margin-bottom: 10px;
      @media screen and (max-width: 1024px) {
        margin-top: 10px;
        margin-bottom: 5px;
        width: 75px;
        height: 75px;
      }
    }
    &--title {
      margin-bottom: 50px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 25px;
        div:first-child{
          font-size: 24px;
        }
        div:last-child{
          font-size: 16px;
        }
      }
    }
    &--terms-text {
      margin-top: 10px;
      color: #adadad;
      text-align: left;
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        width: 295px;
        font-size: 10px !important;
      }
    }
    &--input {
      width: 100%;
      height: 180px;
      @media screen and (max-width: 1024px) {
        width: 295px;
        height: auto;
        margin-bottom: 15px;
        input{
          font-size: 20px;
        }
      }
      .text-field--placeholder{
        font-size: 20px;
        &__active{
          font-size: 12px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 14px;
          &__active{
            font-size: 10px;
          }
        }
      }
      .text-field--description__error{
        text-align: start;
        @media screen and (max-width: 1024px) {
          line-height: 15px;
          margin-top: 5px;
        }
      }
      input{
        text-shadow: 0px 0px 3px #00000029;
      }
    }
    &--code {
      position: relative;
      &--timer {
        position: absolute;
        text-align: center;
        width: 100%;
        top: -34px;
        font-size: 14px;
        font-weight: 700;
        color: #00d8f9;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
          top: -20px;
        }
      }
      &--error{
        text-align: start;
        margin-left: 20px;
        color: #d60505;
        height: 25px;
        line-height: 25px;
        padding-left: 20px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  &__header{
    display: none;
    justify-content: center;
    padding: 18px 0;
    width: 100%;
    background-color: #F7F7F7;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    box-shadow: 0 0 6px #00000029;
    &--burger{
      position: absolute;
      top: 18px;
      right: 31px;
    }
    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }
  &__footer {
    background-color: #fff;
    padding: 26px 86px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    &--description {
      color: #adadad;
      flex: 1.5;
    }
    &--messengers {
      flex: 0.5;
      display: flex;
      margin-left: 84px;
        &--item{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 30px;
            text-decoration: none;
            img{
                max-width: 40px;
                margin-bottom: 10px;
            }
            span{
                font-size: 10px;
                font-weight: normal;
                color: #4B595C;
                text-shadow: 0px 0px 3px #00000029;
            }
        }
    }
    &--phone {
      flex: 1;
      text-align: right;
      a {
        text-decoration: none;
        color: #4b595c;
      }
      &__text {
        color: #adadad;
      }
    }
  }
}

.code-field {
  width: 100% !important;
  input {
    background-color: #fff;
    border: 0 !important;
    border-radius: 12px !important;
    margin-right: 15px;
    font-family: Ubuntu;
    text-shadow: 0px 0px 3px #00000029;
    @extend .bold-40;
    @media screen and (max-width: 1024px) {
      font-size: 20px !important;
      width: 60px !important;
      height: 60px !important;
    }
    &:first-child {
      border-radius: 12px !important;
    }
    &:last-child {
      border-radius: 12px !important;
      border: 0 !important;
      margin-right: 0;
    }
    &:focus {
      border: 0;
      box-shadow: 0px 0px 12px #00d8f9c3;
    }
  }
}

.app-button-center{
  @media screen and (max-width: 1024px) {
    width: 295px;
    height: 50px !important;
    font-size: 16px !important;
  }
}

.sidebar{
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 120;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  animation: sidebar;
  &--background{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &__menu{
    animation: 0.6s fadein;
    position: fixed;
    background-color: #f7f7f7;
    top: 0;
    right: 0;
    width: 310px;
    height: 100%;
    z-index: 3;
    &--header{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 0;
      box-shadow: 0 0 6px #00000029;
      &--close{
        position: absolute;
        right: 31px;
        img{
          width: 30px;
          height: 30px;
        }
      }
    }
    .app-button-center{
      margin: 0 auto;
      width: 120px;
      font-size: 16px;
      margin-bottom: 10px;
      background: #fff;
      letter-spacing: 0px;
      color: #4B595C;
      text-shadow: 0px 0px 3px #00000029;
      border-radius: 15px;
    }
    &--messengers{
      display: flex;
      justify-content: space-between;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-decoration: none;
        color: black;
        background-color: #fff;
        border-radius: 10px;
        width: 82px;
        height: 82px;
        font-size: 10px;
        span{
          margin-top: 10px;
        }
      }
    }
    &--text{
      display: flex;
      flex-direction: column;
      span{
        color: #ADADAD;
        font-size: 10px;
        margin: 10px 0;
      }
      a{
        text-align: center;
        color:#4B595C;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
      }
    }
    .menu{
      display: flex;
      flex-direction: column;
      height: calc(100% - 76px);
      overflow-y: scroll;
      padding: 0 16px 20px;
      &--item{
        display: flex;
        align-items: center;
        padding: 8px 15px;
        background-color: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        &--icon{
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }
        &--text{
          font-size: 16px;
          font-weight: 500;
          text-shadow: 0 0 3px #00000029;
          color: #4b595c;
        }
      }
    }
    &.fade-out{
      animation: 0.6s fadeout;
    }
  }
}

@keyframes fadein {
  from {
    right: -310px;
  }
  to {
    right: 0;
  }
}

@keyframes sidebar {
  from{
    display: none;
  }
  to{
    display: flex;
  }
}

@keyframes fadeout {
  from {
    right: 0;
    display: block;
  }
  to {
    right: -310px;
    display: none;
  }
}