@import "../../sass/color.scss";
/* FOOTER */
.c-footer {
  display: flex;
  flex-direction: column;
  background-color: $color-mobile-bg-outside;
  padding: 20px 100px;
  @media screen and (max-width: 1024px) {
    margin-top: 30vh;
    padding: 0 16px 87px;
    div:first-child{
      display: none;
    }
  }
  .link-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    &-item {
      padding: 0 !important;
      color: $color-button-grey;
      font-size: 14px;
      text-decoration: none;
      font-weight: 700;
      &-sideward {
        width: 40%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  &-copyright {
    text-align: left;

    &-box {
      margin-bottom: 10px;
    }
    color: $color-button-grey;
    font-size: 14px;
  }
  &-spacer {
    width: 158px;
  }
  &-logo{
    width: 17%;
  }
}

.protected-text {
  font-size: 14px;
  color: #636363;
  & a {
    color: #6d6c6c;
    text-decoration: underline;
  }
}
