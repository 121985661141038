.text-shadow-0-0-3 {
  text-shadow: 0 0 3px #00000029;
}

.bold-60 {
  font-size: 40px;
  font-weight: 700;
}

.bold-40 {
  font-size: 40px;
  font-weight: 700;
}

.bold-30 {
  font-size: 30px;
  font-weight: 700;
}

.bold-25 {
  font-size: 25px;
  font-weight: 700;
}
.bold-20 {
  font-size: 20px;
  font-weight: 700;
}

.bold-16 {
  font-size: 16px;
  font-weight: 700;
}

.bold-14 {
  font-size: 14px;
  font-weight: 700;
}

.bold-12 {
  font-size: 12px;
  font-weight: 700;
}
.bold-10 {
  font-size: 12px;
  font-weight: 700;
}

.medium-24 {
  font-size: 24px;
  font-weight: 500;
}

.medium-20 {
  font-size: 20px;
  font-weight: 500;
}

.medium-18 {
  font-size: 18px;
  font-weight: 500;
}
.medium-16 {
  font-size: 16px;
  font-weight: 500;
}

.medium-14 {
  font-size: 14px;
  font-weight: 500;
}
.medium-12 {
  font-size: 12px;
  font-weight: 500;
}

.regular-14 {
  font-size: 14px;
  font-weight: 400;
}
.regular-12 {
  font-size: 12px;
  font-weight: 400;
}
