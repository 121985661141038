.app-button {
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #fff;
  background: transparent linear-gradient(180deg, #00b0f0 0%, #00d8f9 100%) 0%
    0% no-repeat padding-box;

  &:disabled {
    background: #dedede 0% 0% no-repeat padding-box;
  }
  &:focus {
    outline: none;
  }
  &__left {
    text-align: left;
    flex: 1;
  }
  &__right {
    text-align: right;
  }
}
