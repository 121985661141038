.profile {
  padding-top: 10px;
  @media screen and (max-width: 1024px) {
    margin-top: 71px;
  }
  &--title{
    @media screen and (max-width: 1024px) {
      font-size: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .text-field--description {
    height: 10px;
    line-height: 10px;
    &.text-field--description__error {
      height: 20px;
      line-height: 20px;
      padding: 4px 0;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin: 0;
    }
    &--item {
      width: 33%;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      @media screen and (max-width: 1024px) {
        width: 100%;
        justify-content: start !important;
      }
      &__container {
        width: 340px;
      }
      &:nth-child(3n - 1) {
        justify-content: center;
      }
      &:nth-child(3n) {
        justify-content: flex-end;
      }
    }
    &--add {
      user-select: none;
      cursor: pointer;
      height: 80px;
      background-color: #fff;
      color: #dedede;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      position: relative;
      @media screen and (max-width: 1024px) {
        height: 56px;
        margin-bottom: 10px;
      }
      &__title{
        @media screen and (max-width: 1024px) {
          font-size: 16px;
          &.active{
            color: #00d8f9;
         }
        }
      }
      &__icon {
        position: absolute;
        right: 20px;
        img {
          width: 30px;
          height: 30px;
          @media screen and (max-width: 1024px) {
            width: 23px;
            height: 23px;
            padding: 0;
          }
        }
      }
    }
  }
  &-account{
    @media screen and (max-width: 1024px) {
      height: 56px;
    }
    &__name{
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
    &__remove{
      @media screen and (max-width: 1024px) {
        padding: 0;
        img{
          width: 23px;
          height: 23px;
        }
      }
    }
  }
  &-address{
    &__name{
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
    &__address{
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
    &__remove{
      @media screen and (max-width: 1024px) {
        padding: 0;
        img{
          width: 23px;
          height: 23px;
        }
      }
    }
  }
  &__form {
    margin-bottom: 30px;
    .text-field{
      @media screen and (max-width: 1024px) {
        width: 295px;
        margin: 0 auto;
        input{
          height: 50px;
          font-size: 16px;
        }
        .text-field--placeholder{
          top: 16px;
        }
        .text-field--placeholder__active{
          top: 8px;
        }
      }
    }
    &--title {
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &--row {
      display: flex;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
      &__item {
        flex: 1;
        &:first-child {
          margin-right: 10px;
          @media screen and (max-width: 1024px) {
            margin-right: 0;
          }
        }
      }
    }
    &--button {
      margin-top: 10px;
      @media screen and (max-width: 1024px) {
        text-align: center;
        button{
          width: 295px !important;
        }
      }
    }
  }
}
