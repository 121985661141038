.profile-account {
  background-color: #fff;
  border-radius: 12px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  &__phone {
    color: #dedede;
    margin-bottom: 3px;
  }
  &__name {
    color: #4b595c;
  }
  &__remove {
    position: absolute;
    right: 20px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
