.modal-app {
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 200;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 80px;
  transform: translateX(-50%);
  transition: 0.15 ease;
  -webkit-overflow-scrolling: touch;
  overflow: auto;

  &__container {
    position: relative;
    z-index: 2000;
    overflow: hidden;
    box-shadow: 0px 0px 3px #00000029;
    max-width: 850px;
    border-radius: 26px;
    animation-name: modal-animation;
    animation-duration: 0.15s;
    animation-iteration-count: 1;
    width: 375px;
    @media screen and (max-width: 1024px) {
      border-radius: 0;
      width: auto;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__content {
    height: 400px;
    background: #f7f7f7;
    padding: 20px;
    color: #4b595c;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0px 0px 3px #00000029;
    overflow-y: auto;
    @media screen and (max-width: 1024px) {
      height: 80%;
    }
  }
  &__buttons {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    &--mobile {
      display: flex;
      flex-direction: row;
    }
  }
  &__button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    &--mobile {
      margin-top: 20px;
      button {
        max-width: 150px;
      }
    }
    &--item {
      height: 50px;
      max-width: 250px;
    }
  }
  &__footer {
    button {
      width: 250px;
      height: 50px;
      font-size: 16px;
      @media screen and (max-width: 1024px) {
        width: 295px;
      }
    }
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  box-shadow: 0px 0px 6px #00000029;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  position: relative;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.3);
  position: fixed;
  cursor: pointer;
  transition: 0.5s ease;
  animation: modal-animation-overlay;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes modal-animation {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes modal-animation-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.3);
  position: fixed;
  cursor: pointer;
  transition: 0.5s ease;
  animation: modal-animation-overlay;
  animation-duration: 1s;
  animation-iteration-count: 1;
  left: 0;
  top: 0;
}

.modal-services__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  background: #f7f7f7;
  position: relative;
  box-shadow: 0px 0px 6px #00000029;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    height: 10%;
    &__container{
      padding: 18px !important;
    }
    &--icon {
      margin-right: 18px !important;
    }
  }

  &__container {
    padding: 16px;

    display: flex;
    align-items: center;
  }
  &--icon {
    margin-right: 16px;

    img {
      width: 50px;
      height: 50px;
    }
  }
  &--title {
    font-size: 24px;
    font-weight: 500;
    width: 220px;
    color: #4b595c;
  }
  &--description {
    width: 234px;
    font-size: 12px;
    color: #adadad;
    min-height: 26px;
  }
  &--button {
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    color: #00d8f9;
  }
  &--close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      top: 26px;
      right: 18px;
    }

    img {
      width: 45px;
      height: 45px;
    }
  }
}

.app-modal-message {
  background: #fff;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 18px;
  color: #4b595c;
  font-weight: 500;
  text-shadow: 0px 0px 3px #00000029;
  position: relative;
  a {
    color: #4b595c;
    &:hover {
      text-decoration: none;
    }
  }
  &__hook {
    user-select: none;
    position: absolute;
    bottom: -9px;
    left: 10px;
  }
}
