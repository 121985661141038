.home {
  display: flex;
  justify-content: space-between;
  min-height: (100vh - 40vh);
  @media screen and (max-width: 1024px) {
    padding: 20px 16px 0;
    flex-direction: column;
    align-items: center;
    margin-top: 71px;
  }
}
.home-item {
  position: relative;
  cursor: pointer;
  width: 340px;
  height: 340px;
  background-color: #fff;
  border-radius: 24px;
  padding: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-right: 87px;
  color: #4b595c;
  @media screen and (max-width: 1024px) {
    margin-right: 0;
    height: 166px;
    width: 343px;
    margin-bottom: 12px;
  }
  &:last-child {
    margin-right: 0;
  }
  &__description {
    font-size: 14px;
    font-weight: 700;
    color: #00d8f9;
    text-align: center;
    position: absolute;
    bottom: -50px;
    @media screen and (max-width: 1024px) {
      z-index: 1;
      bottom: 50px;
      font-size: 10px;
    }
  }
  &__title{
    @media screen and (max-width: 1024px) {
      font-size: 18px !important;
    }
  }
  &__icon {
    margin-bottom: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      margin-bottom: 27px;
      img{
        width: 68px !important;
        height: 68px !important;
      }
    }
    img {
      width: 140px;
      height: 140px;
    }
  }
}
