.pagination {
  display: flex;
  justify-content: flex-end;
  &--page {
    background: #fff;
    width: 40px;
    height: 40px;
    border: solid 1 #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 8px;
    &__active {
      font-weight: 700;
      background: transparent linear-gradient(180deg, #00b0f0 0%, #00d8f9 100%)
        0% 0% no-repeat padding-box;
      color: #fff;
    }
  }
}
