@import "../../sass/color";
@import "../../sass/typography";

.text-field {
  position: relative;

  &--autocomplete {
    position: absolute;
    top: 60px;
    width: 100%;
    background-color: #fff;
    padding-top: 5px;
    z-index: 10;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border: solid 1px #f2f2f2;
    @media screen and (max-width: 1024px) {
      top: 50px;
    }
  }
  &__container {
    &--error {
      input {
        box-shadow: 0px 0px 12px #d60505c3;
      }
    }
    &--bold {
      input {
        font-weight: 700;
      }
    }

    &--item {
      padding: 8px 20px;
      border-bottom: solid 1px #f2f2f2;
      &:last-child {
        border-bottom: 0;
      }
      &--top {
        color: $BLACK;
        @extend .medium-16;
      }
      &--bottom {
        color: #adadad;
        @extend .regular-14;
      }
    }
    &--list {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  input {
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
    border: 0;
    padding: 10px 20px 0 20px;
    color: $BLACK;
    height: 60px;
    @media screen and (max-width: 1024px) {
      height: 50px;
      font-size: 16px;
    }

    @extend .medium-20;
    &::placeholder {
      color: #adadad;
    }
    &:focus {
      outline: none;
      box-shadow: 0px 0px 12px #00d8f9c3;

      &::placeholder {
        color: transparent;
      }
    }
  }
  &--description {
    height: 25px;
    line-height: 25px;
    padding-left: 20px;
    color: #adadad;
    @extend .medium-12;
    @media screen and (max-width: 1024px) {
      line-height: 16px;
      height: 16px;
      font-size: 10px;
    }
    &__error {
      color: #d60505;
    }
  }
  &--placeholder {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #adadad;
    transition: 0.1s ease;
    pointer-events: none;
    user-select: none;
    font-weight: 500;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      top: 16px;
      font-size: 14px;
    }
    &__active {
      top: 10px;
      font-size: 12px;
      @media screen and (max-width: 1024px) {
        top: 8px;
      }
    }
  }
}
