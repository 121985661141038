.header {
  display: flex;
  margin-top: 44px;
  padding: 0 86px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  &__icon {
    cursor: pointer;
    margin-left: -5px;
  }
  &__content {
    margin-left: 15px;
    &--title {
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        margin-top: 20px;
      }
    }

    &--phone{
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }

    &--name {
      margin-bottom: 3px;
      min-height: 30px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
        min-height: auto !important;
        color: #ADADAD;
        text-shadow: none;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    &--phone {
      color: #adadad;
    }
  }
  &__right{
    margin-left: auto;
    display: flex;

    button{
      min-width: 160px;
      height: 50px;
      background: #fff;
      font-size: 20px;
      letter-spacing: 0px;
      color: #4B595C;
      text-shadow: 0px 0px 3px #00000029;
      border-radius: 15px;
      &:last-child{
        margin-left: 20px;
      }
    }
  }
}
