@import "./color.scss";
@import "./typography.scss";
@import "./spacing.scss";
body {
  margin: 0;
  padding: 0;
  font-family: Ubuntu, sans-serif;
  background-color: #f7f7f7;
  color: $BLACK;
}

ul {
  margin: 0;
  padding: 0;
}
input,
button {
  font-family: Ubuntu !important;
}

.content {
  padding: 0 86px;
}

h1 {
  font-size: 60px;
  font-weight: 700;
  padding-top: 36px;
  padding-bottom: 10px;
  margin: 0;
  @extend .text-shadow-0-0-3;
}

.position-relative {
  position: relative;
}

.grecaptcha-badge {
  visibility: hidden;
}

.rouble {
  font-family: Helvetica Neue;
}

.page {
  padding: 0 86px;
  min-height: calc(100vh - 110px);
  @media screen and (max-width: 1024px) {
    min-height: auto !important;
    padding: 0 20px;
  }
}

button {
  background-color: transparent;
  border: 0;
  outline: none;
}
