@import "../../sass/typography.scss";

.create {
  @media screen and (max-width: 1024px) {
    margin-top: 71px;
    padding-top: 20px;
  }
  .lightgray{
    @media screen and (max-width: 1024px) {
      .text-field--placeholder{
        color: #E3E3E3;
      }
    }
  }
  &--title {
    color: #4b595c;
    margin-bottom: 8px;
    font-size: 16px;
    @extend .bold-25;
    @media screen and (max-width: 1024px) {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 0;
      &:first-child{
        margin-top: 0;
      }
    }
  }
  &--description{
    display: none;
    font-size: 10px;
    color: #ADADAD;
    margin-bottom: 10px;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  &--row {
    display: flex;
    .row{
      display: flex;
      &:first-child{
        margin-right: 10px;
      }
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      .row{
        display: block;
        &:first-child{
          margin-right: 0;
        }
      }
      &.row{
        display: flex;
        flex-direction: row;
        .mr-9{
          margin-right: 9px;
        }
      }
      &.reverse{
        flex-direction: column-reverse;
      }
    }
    &__item {
      flex: 1;
      margin-right: 10px;
      @media screen and (max-width: 1024px) {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &--termo{
        display: flex;
        margin-left: 28px;
        @media screen and (max-width: 1024px) {
          justify-content: space-between;
          align-items: center;
          margin-left: 0;
        }
      }
      &--desc{
        color: #adadad;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        &--text{
          max-width: 85%;
        }
        @media screen and (max-width: 1024px) {
          padding-left: 20px;
          &--title{
            font-size: 14px !important;
          }
          &--text{
            max-width: 100%;
            font-size: 10px !important;
          }
        }
      }
      &--btn{
        display: flex;
        flex: 1;
        @media screen and (max-width: 1024px) {
          flex: none;
          button{
            min-width: 50px !important;
            min-height: 50px !important;
            height: 50px;
            width: 50px;
            font-size: 14px;
            font-weight: 500;
            padding: 0;
          }
        }
        &--active{
          display: flex;
          flex: 1;
          @media screen and (max-width: 1024px) {
            flex: none;
            button{
              min-width: 50px !important;
              min-height: 50px !important;
              height: 50px;
              width: 50px;
              font-size: 14px;
              font-weight: 500;
              padding: 0;
            }
          }
          button{
            min-width: 84px;
            font-size: 16px;
            padding: 0;
          }
          button:last-child{
            border-radius: 15px;
            margin-left: 10px;
            background: #fff;
            letter-spacing: 0px;
            color: #ADADAD;
          }
        }
        button{
          min-width: 84px;
          font-size: 16px;
          padding: 0;
        }
        button:first-child{
          border-radius: 15px;
          background: #fff;
          letter-spacing: 0px;
          color: #ADADAD;
        }
        button:last-child{
          margin-left: 10px;
        }
      }
      &--payment{
        display: flex;
        color: #adadad;
        margin-left: 28px;
        @media screen and (max-width: 1024px) {
          margin-left: 0;
          margin-bottom: 10px;
          align-items: center;
          padding-left: 20px;
          &--title{
            font-size: 14px !important;
          }
          &--text{
            max-width: 100% !important;
            font-size: 10px !important;
          }
        }
        &--text{
          max-width: 85%;
        }
        button{
          font-size: 16px;
          min-width: 84px;
          width: 84px;
          max-width: auto;
          background: #fff;
          color: #ADADAD;
          margin-left: -20px;
          @media screen and (max-width: 1024px) {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        &--active{
          display: flex;
          color: #adadad;
          @media screen and (max-width: 1024px) {
            margin-left: 0;
            margin-bottom: 10px;
            &--title{
              font-size: 14px !important;
            }
            &--text{
              max-width: 100% !important;
              font-size: 10px !important;
            }
          }
          &--text{
            max-width: 85%;
          }
          button{
            margin-left: -20px;
            font-size: 16px;
            min-width: 84px;
            width: 84px;
            max-width: auto;
            @media screen and (max-width: 1024px) {
              width: 50px;
              height: 50px;
              min-width: 50px;
              min-height: 50px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    &__btn{
      display: flex;
      button:last-child{
        margin-left: 10px;
      }
      @media screen and (max-width: 1024px) {
        button{
          height: 160px !important;
          padding: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .app-button__left{
            font-size: 14px;
            div{
              font-size: 8px;
            }
          }
        }
      }
    }
  }
  &--amount {
    text-align: right;
    @media screen and (max-width: 1024px) {
      div{
        &:first-child{
          font-size: 14px;
        }
        &:last-child{
          font-size: 10px;
        }
      }
    }
  }
}



@media screen and (max-width: 1024px) {
  .hidden-mobile{
    display: none;
  }
  .hidden-desktop{
    display: block !important;
  }
}

.hidden-desktop{
  display: none;
  position: relative;
}

.mobile-slots{
  width: 100%;
  border: none;
  height: 50px;
  padding: 16px 16px 0;
  background: white;
  margin: 10px 0;
  border-radius: 12px;
  color: gray;
  font-size: 16px;
  color: #424242;
  font-weight: 500;
  font-family: Ubuntu !important;
  -webkit-appearance: none;
  &--placeholder{
    position: absolute;
    color: #adadad;
    user-select: none;
    position: absolute;
    top: 28px;
    left: 16px;
    font-family: Ubuntu !important;
    font-weight: 500;
    font-size: 14px;
    &.active{
      top: 20px;
      font-size: 10px;
    }
  }
}
