.tabs {
  display: flex;
  &--item {
    padding: 10px 15px;
    border-radius: 10px;
    background: #dedede;
    cursor: pointer;
    color: #fff;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &__active {
      background: transparent linear-gradient(180deg, #00b0f0 0%, #00d8f9 100%)
        0% 0% no-repeat padding-box;
    }
  }
}
